<template>
  <div class="building-album">
    <navbar title="新房相册" />
    <van-tabs v-model="active" animated swipe-threshold="1" background="#F3F3F3" title-active-color="#00A362" title-inactive-color="#444" color="#00A362">
      <van-tab v-for="(item, index) in albumList" :key="index">
        <template #title>
          <div class="tabs-name">{{ item.name }}</div>
          <div class="tabs-num">{{ item.imgList.length }}张</div>
        </template>
        <div class="img-box">
          <div class="img-item" v-for="(item01, index01) in item.imgList" :key="index01">
            <img :src="item01" @click="showImg(index,index01)" alt="" />
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import navbar from "../../components/NavBar.vue";
import { ImagePreview } from "vant";
export default {
  name: "buildingAlbum",
  data() {
    return {
      albumList: [
        {
          name: "测试相册01",
          imgList: [
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
          ],
        },
        {
          name: "测试相册02",
          imgList: [
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
          ],
        },
        {
          name: "测试相册03",
          imgList: [
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
          ],
        },
        {
          name: "测试相册04",
          imgList: [
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
          ],
        },
        {
          name: "测试相册05",
          imgList: [
            require("../../assets/images/swiper01.jpg"),
            require("../../assets/images/swiper01.jpg"),
          ],
        },
        {
          name: "测试相册06",
          imgList: [require("../../assets/images/swiper01.jpg")],
        },
        {
          name: "测试相册07",
          imgList: [],
        },
      ],
      active: 0,
    };
  },
  methods: {
    showImg(index, index01) {
      ImagePreview({
        images: this.albumList[index].imgList,
        closeable: true,
        startPosition: index01,
      });
    },
  },
  components: {
    navbar,
  },
};
</script>
<style lang="less" scoped>
.building-album {
  /deep/.van-tabs {
    .van-tabs__wrap {
      height: 50px;
      border-bottom: 1px solid #ddd;
    }
    .van-tab__text {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .img-box {
      display: grid;

      justify-content: space-evenly;
      grid-template-columns: repeat(auto-fill, 110px);
      .img-item {
        width: 110px;
        height: 80px;
        margin-top: 10px;
        img {
          width: 110px;
          height: 80px;
        }
      }
    }
  }
}
</style>